<template>
  <card shadow class="flex" style="height: 120px;">
    <img :src="img" :style="isMobile ? 'height:90px;width:103px;' : 'height:80px;'" />
    <div>
      <h6 class="font-bold pl-2 text-sm md:text-2xl mb-4">Jam Operasional</h6>
      <div class="flex text-xs pl-2 md:text-sm grid-cols-2 gap-4">
        <p>
          <strong>Senin - Jum'at</strong>
          <br/>8 Pagi - 5 Sore (WIB)
        </p>
        <p>
          <strong>Sabtu</strong>
          <br/>8 Pagi - 12 Siang (WIB)
        </p>
        <!-- <p v-if="!isSm">
          <strong>Minggu</strong>
          OFF
        </p>
        <p v-else>
          <strong>Whatsapp</strong>
          <br>+62 811 771 0880
        </p> -->
        <!-- <b-button v-if="isSm" type="is-warning-gradient" class="mt-4" @click="connectChat">CHAT</b-button> -->
        </div>
      <!-- <b-button v-if="!isSm" type="is-warning-gradient" class="mt-4" @click="connectChat">CHAT</b-button> -->
    </div>
  </card>
</template>

<script>
// import img from '@/assets/ipost/contact.png'
import Card from './Card'
export default {
  components: {
    Card
  },
  data () {
    return {
      img: 'https://elsoft.s3-ap-southeast-1.amazonaws.com/bf713ad1-33f1-11eb-a444-82afd875af05/images/175cebf4-d168-4bb7-94ba-e2dafb8ddd35.png'
    }
  },
  methods: {
    async connectChat () {
      const loading = this.$buefy.loading.open()
      try {
        this.$store.dispatch('socket/getHistory')
        this.$router.push('/user/chat')
      } catch (err) {
      } finally {
        loading.close()
      }
    }
  }
}
</script>

<style>

</style>

<template>
  <div @click="$emit('click')" :class="['rounded-2xl bg-white', 'p-' + this.padding, { shadow }]"><slot></slot></div>
</template>

<script>
export default {
  props: {
    padding: {
      type: [String, Number],
      default: '4'
    },
    shadow: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style>

</style>
